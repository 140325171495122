import React, { useState, useEffect } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as styles from '../templates/project.module.scss'

const ProjectImage = ({ image, onLoad = () => {} }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    loaded && onLoad()
  }, [loaded])

  // Convert the fluid image to gatsby-plugin-image format
  const gatsbyImage = getImage(image)

  return (
    <div
      className={styles.projectImageWrapper}
      onDragStart={e => e.preventDefault()}
    >
      <div className={styles.projectImage}>
        {image.placeholder && (
          <img
            src={image.placeholder.src}
            className={styles.projectImagePlaceholder}
            alt=""
          />
        )}
        <GatsbyImage
          image={gatsbyImage}
          alt=""
          className={[styles.projectImageFull, loaded ? styles.isLoaded : ''].join(' ')}
          onLoad={() => setLoaded(true)}
        />
      </div>
    </div>
  )
}

export default ProjectImage