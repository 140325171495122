// extracted by mini-css-extract-plugin
export var isLoaded = "project-module--isLoaded--04f7a";
export var projectContent = "project-module--projectContent--ff32c";
export var projectCopy = "project-module--projectCopy--5b572";
export var projectImage = "project-module--projectImage--bd45b";
export var projectImageFull = "project-module--projectImageFull--86735";
export var projectImagePlaceholder = "project-module--projectImagePlaceholder--6e2e6";
export var projectImageWrapper = "project-module--projectImageWrapper--532f5";
export var projectImages = "project-module--projectImages--d72e8";
export var projectImagesWrapper = "project-module--projectImagesWrapper--ae9e6";
export var projectTitle = "project-module--projectTitle--aff50";
export var projectVideo = "project-module--projectVideo--456a3";
export var projectVideoWrapper = "project-module--projectVideoWrapper--5eacb";
export var projectWrapper = "project-module--projectWrapper--93885";
export var scroller = "project-module--scroller--433a7";